<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card class="pa-5">
            <div class="text-h4 text-center">Update Project</div>
            <v-form ref="form"
            >
              <v-container>
                <v-row class="justify-center">
                  <v-col cols="12" lg="6">
                    <v-alert type="error" v-if="error">{{ error }}</v-alert>
                    <v-text-field
                        v-model="formData.name"
                        label="Name"
                        type="text"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" lg="3">
                    <v-text-field
                        v-model="formData.ref_person"
                        label="Ref Person"
                        type="text"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-text-field
                        label="Contact No"
                        type="number"
                        v-model="formData.contact"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" lg="6">
                    <v-text-field
                        v-model="formData.address"
                        label="Address"
                        type="text"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" lg="6">
                    <v-textarea
                        rows="2"
                        v-model="formData.discription"
                        label="Description"
                        type="text"
                        required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
              <v-row class="justify-center">
                <v-col cols="12" lg="6" class="text-center">
                  <v-btn color="primary" class="px-10" @click="updateProject()" :disabled="loading">Update</v-btn>
                  <br>
                  <v-progress-circular
                      indeterminate
                      color="primary"
                      class="mt-5"
                      v-if="loading"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-overlay :value="full_loader" color="white">
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>
          </v-overlay>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {edit_project, update_project} from "@/apis/project"


export default {
  data() {
    return {
      formData: {
        name: "",
        ref_person: "",
        contact: "",
        address: "",
        discription: ""
      },
      loading: false,
      error: '',
      full_loader: true
    }
  },
  mounted() {
    this.getProject(this.$route.params.id);
  },
  methods: {
    getProject(id) {
      edit_project(id).then(
          resp => {
            this.full_loader = false;
            this.formData.name = resp.data.name;
            this.formData.ref_person = resp.data.ref_person;
            this.formData.contact = resp.data.contact;
            this.formData.address = resp.data.address;
            this.formData.discription = resp.data.discription;
          }
      )
    },
    updateProject() {
      this.loading = true;
      this.error = '';
      update_project(this.$route.params.id, this.formData).then(
          resp => {
            this.loading = false
            if (resp.status == 'Success') {
              this.$toastr.s(resp.message);
              this.$router.push('/projects')
            } else {
              this.error = resp.errors;
            }
          }
      )
    }
  }
}
</script>

<style>

</style>